import React, { Component } from "react";
import {
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Nav,
  Loader,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PrintClass from "../../../components/general/Print";

import OverallDay from "../../../components/performances/overall-performance/OverallDay";
import Overall from "../../../components/performances/overall-performance/Overall";
import OverallAgency from "../../../components/performances/overall-performance/OverallAgency";

import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";
import moment from "moment";

export default class OverallPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // LOADERS
      isLoad: false,
      isLoadAgency: false,
      isPrint: false,
      // Data
      data: null,
      agencyData: null,
      dayData: null,
      tabsIdArray: [],
      tabsId: 0,
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      dateStart: 0,
      dateEnd: 0,
    };

    this.refPrintOverall = React.createRef();
    this.refPrintOverallAgency = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.isLoad === false) {
        this.getData(false);
        this.getAgencies();
        this.getDataDay();
      }
    }, 15 * 60 * 1000);
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
      },
      () => {
        this.getData();
        this.getAgencies();
        this.getDataDay();
      }
    );
  };

  getAgencies = () => {
    axiosConfig({
      url: "/performances/overall/get-agencies",
      data: {
        network: this.state.network,
        group: this.state.group,
        region: this.state.region,
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          this.setState(
            {
              tabsIdArray: res.data.data,
              tabsId:
                res.data.data.length <= this.state.tabsId
                  ? 0
                  : this.state.tabsId,
            },
            this.getDataAgency
          );
        } else {
          this.setState({
            tabsIdArray: [],
            tabsId: 0,
          });
        }
      })
      .catch(() => {
        this.setState({
          tabsIdArray: [],
          tabsId: 0,
        });
      });
  };

  getData = (isLoad = true) => {
    this.setState({ isLoad }, () => {
      axiosConfig({
        url: "/performances/overall/get-data",
        data: {
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              data: res.data.data,
              isLoad: false,
            });
          } else {
            this.setState({ data: null, isLoad: false });
          }
        })
        .catch(() => {
          this.setState({ data: null, isLoad: false });
        });
    });
  };

  getDataAgency = () => {
    this.setState(
      {
        isLoadAgency: true,
      },
      () => {
        axiosConfig({
          url: "/performances/overall/get-data-agency",
          data: {
            agency: this.state.tabsIdArray[this.state.tabsId]["a_id"],
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
          },
        })
          .then((res) => {
            if (res.data.success === true) {
              this.setState({
                agencyData: res.data.data,
                isLoadAgency: false,
              });
            } else {
              this.setState({ agencyData: null, isLoadAgency: false });
            }
          })
          .catch(() => {
            this.setState({ agencyData: null, isLoadAgency: false });
          });
      }
    );
  };

  getDataDay = () => {
    axiosConfig({
      url: "/performances/overall/get-data-day",
      data: {
        network: this.state.network,
        group: this.state.group,
        region: this.state.region,
        agency: this.state.agency,
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            dayData: res.data.data,
          });
        } else {
          this.setState({ dayData: null });
        }
      })
      .catch(() => {
        this.setState({ dayData: null });
      });
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Performance globale" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isDateStart={true}
            isDateEnd={true}
            isGlobal={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
          />
          {this.state.dayData ? <OverallDay data={this.state.dayData} /> : null}
          {this.state.data ? (
            <div ref={this.refPrintOverall}>
              <Overall
                year={moment(this.state.dateEnd, "YYYY-MM-DD").format("YYYY")}
                data={this.state.data}
              />
            </div>
          ) : null}
          {this.state.tabsIdArray ? (
            <>
              <TabsCustom className={"mb-10 mb-20-xl "}>
                <TabsCustomHeader>
                  {this.state.tabsIdArray.map((element, key) => (
                    <TabsCustomMenu
                      key={key}
                      title={element.a_name}
                      active={this.state.tabsId === key}
                      disabled={this.state.isLoadAgency}
                      onClick={
                        !this.state.isLoadAgency
                          ? (e) => {
                              this.setState(
                                { tabsId: key },
                                this.getDataAgency
                              );
                            }
                          : undefined
                      }
                    />
                  ))}
                </TabsCustomHeader>
                <TabsCustomBody noCard>
                  {this.state.agencyData ? (
                    <TabsCustomItem active={true}>
                      <div ref={this.refPrintOverallAgency}>
                        <OverallAgency
                          year={moment(this.state.dateEnd, "YYYY-MM-DD").format(
                            "YYYY"
                          )}
                          data={this.state.agencyData}
                        />
                      </div>
                    </TabsCustomItem>
                  ) : null}
                </TabsCustomBody>
              </TabsCustom>
            </>
          ) : null}
        </FadeIn>
        <Loader show={this.state.isLoad || this.state.isPrint} />
        <PrintClass
          refs={
            this.state.isPrint
              ? [this.refPrintOverall, this.refPrintOverallAgency]
              : []
          }
          title={"Performance globale"}
          fileName={"Performance globale"}
          onPrint={(print) => this.setState({ isPrint: print })}
          width={1600}
        />
      </>
    );
  }
}
