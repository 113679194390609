import React from "react";
import {
  Nav,
  Card,
  BarChart,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Pagination,
  FormSelectMultiples,
  Loader,
} from "ui-kit-ck-consultant";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";

import axiosConfig from "../../../utils/axiosConfig";

import { colors, getColors } from "../../../utils/colors";

import natureExpertise from "../../../utils/natureExpertise";

import { getCurrencyValue } from "../../../utils/general";

import { Row, Col } from "react-flexbox-grid";

import handleSortClick from "../../../utils/handleSortClick";

import moment from "moment";

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class PrecisiaPilotage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      dates: [],
      dataDay: [],
      folders: [],
      indicatorMultiples: [],
      dataTypeDay: {},
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      expert: 0,
      company: 0,
      // PAGINATION
      activePage: 1,
      pageLength: 100,
      tabLength: 20,
      total: 0,
      order: {
        name: "reference",
        desc: true,
      },
      noResultMessage: false,
    };
    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  componentDidMount() {}

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  getDataPrecisiaPilotage() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("alert/get-data-precisia-pilotage", {
        data: {
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          expert: this.state.expert,
          company: this.state.company,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              dates: res.data.dates,
              dataDay: res.data.dataDay,
              dataTypeDay: res.data.dataTypeDay,
              folders: res.data.folders,
              isLoad: false,
              noResultMessage: res.data.folders.length === 0,
            });
          } else {
            this.setState(
              {
                isLoad: false,
                dates: [],
                dataDay: [],
                dataTypeDay: {},
                folders: [],
              },
              () => {
                alert("Une erreur est survenue");
              }
            );
          }
        })
        .catch(() => {
          this.setState(
            {
              isLoad: false,
              dates: [],
              dataDay: [],
              dataTypeDay: {},
              folders: [],
            },
            () => {
              alert("Une erreur est survenue");
            }
          );
        });
    });
  }

  fitlerFolderList() {
    return this.state.folders.filter((folder) =>
      this.state.indicatorMultiples.length > 0
        ? this.state.indicatorMultiples.includes(folder.label)
        : true
    );
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        expert: data.user,
        company: data.company,
      },
      () => {
        this.getDataPrecisiaPilotage();
      }
    );
  };

  render() {
    const { dates, dataDay, dataTypeDay } = this.state;
    const colorsArray = getColors();
    const theadData = [
      { key: "reference", label: "Référence" },
      { key: "label", label: "Indicateur" },
      { key: "regionName", label: "Région" },
      { key: "agencyName", label: "Agence" },
      { key: "expertName", label: "Expert" },
      { key: "assistantName", label: "Assistant(e)" },
      { key: "companyName", label: "Compagnie" },
      { key: "builder", label: "Marque" },
      { key: "model", label: "Modèle" },
      { key: "natureExpertise", label: "Nature d'expertise" },
      { key: "totalReportDutyFree", label: "Coût réparation HT" },
      { key: "VRADEDutyDree", label: "VRADE HT" },
      { key: "missionDate", label: "Date mission" },
      { key: "EADDate", label: "Date EAD" },
      { key: "visitDate", label: "Date visite avant travaux" },
      { key: "closingDate", label: "Date de cloture" },
    ];

    this.state.folders.sort((a, b) => {
      let aX = a[this.state.order.name] || "";
      let bX = b[this.state.order.name] || "";

      // Date sort
      if (moment(aX, "DD/MM/YYYY").isValid()) {
        const aXDate = moment(aX, "DD/MM/YYYY");
        const bXDate = moment(bX, "DD/MM/YYYY");

        return this.state.order.desc
          ? bXDate.format("YYYYMMDD") - aXDate.format("YYYYMMDD")
          : aXDate.format("YYYYMMDD") - bXDate.format("YYYYMMDD");
      }

      if (typeof aX == "number" && typeof bX == "number") {
        return this.state.order.desc ? aX - bX : bX - aX;
      } else {
        aX = aX.toString();
        bX = bX.toString();
        return this.state.order.desc
          ? aX.normalize().localeCompare(bX.normalize())
          : bX.normalize().localeCompare(aX.normalize());
      }
    });

    return (
      <>
        <Nav className="mb-10 mb-20-xl" title="PrécisIA Pilotage" />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          isCompany={true}
          isUser={true}
          others={
            <Row>
              <Col xs={12} md={4} lg={2} className={"mb-10"}>
                <FormSelectMultiples
                  disabled={!this.state.region}
                  title="Indicateur(s)"
                  value={this.state.indicatorMultiples}
                  default="Tous les indicateurs"
                  options={this.state.folders.reduce((prev, curr) => {
                    if (!prev.some((el) => el.value === curr.label)) {
                      prev.push({ text: curr.label, value: curr.label });
                    }
                    return prev;
                  }, [])}
                  onChange={(value) =>
                    this.setState({
                      indicatorMultiples: [...value],
                    })
                  }
                />
              </Col>
            </Row>
          }
        />
        {Object.keys(dataTypeDay).length > 0 && (
          <Card title="Tous les indicateurs" className={"mb-10 mb-30-xl"}>
            <BarChart
              title=""
              height={460}
              labels={dates}
              digits={0}
              dataSet={[
                {
                  label: "Tous les indicateurs",
                  type: "line",
                  data: dataDay,
                  color: colors.blue[0],
                },
              ]}
            />
          </Card>
        )}
        {Object.keys(dataTypeDay).length > 0 && (
          <Card title="Détails indicateurs" className={"mb-10 mb-30-xl"}>
            <BarChart
              isLegend
              title=""
              height={460}
              labels={dates}
              digits={0}
              dataSet={Object.keys(dataTypeDay).map((key, idx) => ({
                label: key,
                type: "line",
                data: dataTypeDay[key],
                color: colorsArray[idx],
              }))}
            />
          </Card>
        )}
        {this.fitlerFolderList().length > 0 && (
          <Card title="Liste des dossiers" classNameChildren="overflow-x-auto">
            <Table
              isCSV
              getAllData={(callback) =>
                this.setState(
                  { pageLength: this.fitlerFolderList().length, activePage: 1 },
                  callback
                )
              }
              csvCallback={() => this.setState({ pageLength: 100 })}
            >
              <Thead>
                <Tr>
                  <Th>#</Th>
                  {theadData.map((th, idx) => (
                    <Th
                      key={idx}
                      onSort={(desc) => this.handleSortClick(desc, idx)}
                      ref={this.sortRef[idx]}
                      sort
                      nameSort={th.key}
                    >
                      {th.label}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {this.fitlerFolderList()
                  .slice(
                    (this.state.activePage - 1) * this.state.pageLength,
                    this.state.activePage * this.state.pageLength
                  )
                  .map((folder, idx) => (
                    <Tr key={idx}>
                      <Td>
                        {(this.state.activePage - 1) * this.state.pageLength +
                          idx +
                          1}
                      </Td>
                      <Td>{folder.reference}</Td>
                      <Td>{folder.label}</Td>
                      <Td>{folder.regionName}</Td>
                      <Td>{folder.agencyName}</Td>
                      <Td>{folder.expertName}</Td>
                      <Td>{folder.assistantName}</Td>
                      <Td>{folder.companyName}</Td>
                      <Td>{folder.builder}</Td>
                      <Td>{folder.model}</Td>
                      <Td>{natureExpertise(folder.natureExpertise)}</Td>
                      <Td>{getCurrencyValue(folder.totalReportDutyFree)}</Td>
                      <Td>{getCurrencyValue(folder.VRADEDutyDree)}</Td>
                      <Td>{folder.missionDate}</Td>
                      <Td>{folder.EADDate}</Td>
                      <Td>{folder.visitDate}</Td>
                      <Td>{folder.closingDate}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.fitlerFolderList().length}
              pageRangeDisplayed={4}
              onChange={(activePage) => {
                this.setState({ activePage: activePage });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          </Card>
        )}
        {this.state.noResultMessage && <Card>Aucun résultat</Card>}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
