import React, { Component } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { BarChart, Card, Loader, PieChart } from 'ui-kit-ck-consultant'

export class Statistics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conformeBar: [],
      conformeLine: [],
      nonConformeBar: [],
      nonConformeLine: [],
      labelsExpert: [],
      consigneDataPie: []
    }
  }

  setDataBar = () => {
    let conformeBar = []
    let nonConformeBar = []
    let labelsExpert = []
    let colorsExpert = []
    let totalByExpert = []
    if (this.props.data && this.props.data.length > 0) {
      this.props.data.map(element => {
        if (!labelsExpert.includes(element.name)) {
          labelsExpert.push(element.name)
          colorsExpert.push(element.color)
          if (element.value === 1) {
            conformeBar.push(1)
            nonConformeBar.push(0)
          } else {
            nonConformeBar.push(1)
            conformeBar.push(0)
          }
        } else {
          const index = labelsExpert.findIndex(el => el === element.name)
          if (index !== -1) {
            if (element.value === 1) {
              conformeBar[index]++ 
            } else {
              nonConformeBar[index]++
            }
          }
        }
        return element
      })
    }
    labelsExpert.map((element, idx) => totalByExpert.push(conformeBar[idx] + nonConformeBar[idx]))
    return {conformeBar, nonConformeBar, labelsExpert, totalByExpert, colorsExpert}
  }

  setDataLine = () => {
    let conformeLine = []
    let nonConformeLine = []
    if (this.props.data && this.props.data.length > 0) {
      this.props.data.map(element => {
        let month = new Date(element.date).getMonth()
        if (element.value === 1) {
          conformeLine[month] = conformeLine[month] ? conformeLine[month]+1 : 1
        } else {
          nonConformeLine[month] = nonConformeLine[month] ? nonConformeLine[month]+1 : 1
        }
        return element
      })
    }
    return {conformeLine, nonConformeLine}
  }

  setDataPie = (labelsExpert, totalByExpert, colorsExpert) => {
    let consigneDataPie = []
    if (this.props.data && this.props.data.length > 0) {
      let total = this.props.data.length
      labelsExpert.map((element, idx) => 
        consigneDataPie.push({
          data: totalByExpert[idx] ? ((totalByExpert[idx] * 100) / total).toFixed(2) : 0,
          labels: element,
          color: colorsExpert[idx]
        })
      )
    }
    return {consigneDataPie}
  }

  setDataLineIndicator = (colorsExpert) => {
    let indicators = []
    let indicatorsLabels = []
    if (this.props.data && this.props.data.length > 0) {
      this.props.data.map((element) => {
        let month = new Date(element.date).getMonth()
        if (!indicatorsLabels.includes(element.indicateur)) {
          indicators.push([])
          indicators[indicatorsLabels.length] = [null, null, null, null, null, null, null, null, null, null, null, null]
          indicators[indicatorsLabels.length][month] = 1
          indicatorsLabels.push(element.indicateur)
        } else {
          const index = indicatorsLabels.findIndex(el => el === element.indicateur)
          if (index !== -1) {
            indicators[index][month]++
          }
        }
        return element
      })
    }
    let dataIndicator = []
    indicatorsLabels.map((element, idx) => 
      dataIndicator.push({
        label: `Indicateur nº${element}`,
        type: 'line',
        data: indicators[idx],
        color: colorsExpert[idx]
      })
    )
    return {dataIndicator}
  }

  render() {
    const { conformeBar, nonConformeBar, labelsExpert, totalByExpert, colorsExpert } = this.setDataBar()
    const { conformeLine, nonConformeLine } = this.setDataLine()
    const { consigneDataPie } = this.setDataPie(labelsExpert, totalByExpert, colorsExpert) 
    const { dataIndicator } = this.setDataLineIndicator(colorsExpert)
    return (
      <Card 
        className={'mt-30 mb-30'}
        title={'Graphiques'}
      >
        {this.props.filters}
        <Row>
          {conformeBar && nonConformeBar 
            ?
              <Col className={'mt-10'} md={6} xs={12} >
                <BarChart 
                  title={"Total consigne par expert"}
                  digits={0}
                  isLegend={true}
                  labels={labelsExpert && labelsExpert}
                  xAxes={[
                    {
                      stacked: true,
                    },
                  ]}
                  yAxes={[
                    {
                      stacked: true,
                      gridLines: {
                        display: false
                      },
                      display: false
                    },
                  ]}
                  dataSet={[
                    {
                      type: 'bar',
                      label: 'Conforme',
                      data: conformeBar,
                      color: 'green',
                    },
                    {
                      type: 'bar',
                      label: 'Non conforme',
                      data: nonConformeBar,
                      color: 'red',
                    },
                  ]}
                  
                />
              </Col>
            : null }
          {conformeLine && nonConformeLine 
            ?
              <Col className={'mt-10'} md={6} xs={12} >
                <BarChart 
                  title={'Nombre de consignes par mois'}
                  digits={0}
                  isLegend={true}
                  dataSet={[
                    {
                      type: 'line',
                      label: 'Conforme',
                      data: conformeLine,
                      color: 'green',
                    },
                    {
                      type: 'line',
                      label: 'Non conforme',
                      data: nonConformeLine,
                      color: 'red',
                    },
                  ]}
                />
              </Col>
            : null }
          {consigneDataPie 
            ?
              <Col className={'mt-10'} md={6} xs={12} >
                <PieChart
                  digits={0}
                  title={'Taux de consignes par expert'}
                  format="percentage"
                  legendDisplay={true}
                  height={250}
                  data={consigneDataPie && consigneDataPie}
                />
              </Col>
            : null }
          {dataIndicator 
            ?
              <Col className={'mt-10'} md={6} xs={12} >
                <BarChart 
                  title={'Nombre de consignes par mois par indicateur'}
                  digits={0}
                  isLegend={true}
                  dataSet={dataIndicator.length > 0 && dataIndicator}
                />
              </Col>
            : null }
        </Row>
          <Loader show={this.props.loader} />
      </Card>
    )
  }
}

export default Statistics;
